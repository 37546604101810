<template>
  <b-list-group-item
    class="p-0 d-flex align-items-stretch justify-content-between"
  >
    <!------------ START: Item text ------------>
    <div class="btn btn-clean rounded-0 text-left flex-grow-1 px-3 py-2" @click="select">
      <i ref="value" class="fal fa-circle-info align-middle mr-1" />
      <span class="align-middle">{{ text }}</span>
    </div>
    <!------------ END: Item text ------------>
    <!------------ START: Item show children ------------>
    <div
      v-if="hasChildren"
      class="btn btn-clean rounded-0 px-3 py-2 border-left"
      @click="openDetails"
    >
      <i class="fal fa-angle-right" />
    </div>
    <!------------ END: Item show children ------------>
    <b-tooltip
      :target="() => $refs.value"
      placement="top"
      noninteractive
      custom-class="tooltip-custom"
    >
      <div class="font-weight-bold">{{ $t("formHelper.value") }}</div>
      <pre class="text-left mb-0">{{ value }}</pre>
    </b-tooltip>
  </b-list-group-item>
</template>

<script>
export default {
  components: {},
  props: {
    set: {
      type: Object,
      default: () => {}
    },
    text: {
      type: String,
      default: ""
    },
    value: {
      type: [String, Number, Object, Array],
      default: ""
    }
  },
  data() {
    return {};
  },
  computed: {
    // Return whether variable's value is an object
    hasChildren: function () {
      return typeof this.value === "object";
    }
  },
  mounted() {},
  methods: {
    // Emit select event
    select() {
      this.$emit("select", this.text);
    },
    // Emit event to add carousel item with variable's object value as items
    openDetails() {
      const payload = {
        set: this.set,
        text: this.text,
        value: this.value
      };
      this.$emit("open", payload);
    }
  }
};
</script>
