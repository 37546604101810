<template>
  <div>
    <div class="mb-2">
      <span class="btn btn-sm btn-clean btn-icon mr-2" @click="back">
        <i class="fal fa-angle-left" />
      </span>
      <span class="font-weight-bold">
        {{ item.set.prefix }}.{{ item.name }}
      </span>
    </div>
    <b-list-group>
      <SetItem
        v-for="(value, key) in variablesFiltered"
        :key="key"
        :set="item.set"
        :text="key"
        :value="value"
        @open="open"
        @select="select"
      />
    </b-list-group>
  </div>
</template>

<script>
import SetItem from "@/components/Tools/FormHelper/Components/VariablesDropdown/SetItem";

export default {
  components: { SetItem },
  props: {
    item: {
      type: Object,
      default: () => {
      }
    },
    filter: {
      type: String,
      default: ""
    }
  },
  data() {
    return {};
  },
  computed: {
    variablesFiltered: function () {
      // If no filter given, return all items
      if (!this.filter) {
        return this.item.variables;
      }
      // Else filter items
      let variables = {};
      let keys = Object.keys(this.item.variables).filter(key => {
        // Extend item name with prepended prefix of set
        let variableName = [this.item.set.prefix, this.item.name, key].join(
          "."
        );
        // Get variable name length
        let variableNameLength = variableName.split(".").length;
        // Split filter string by dots
        let filter = this.filter.split(".");
        if (filter[0] === this.item.set.prefix) {
          // If filters first string equals prefix,
          filter = filter.slice(0, variableNameLength);
        } else if (filter.length > 1) {
          filter.shift();
          filter = filter.slice(0, variableNameLength - 1);
        }
        return variableName.includes(filter.join("."));
      });
      keys.forEach(key => (variables[key] = this.item.variables[key]));
      return variables;
    }
  },
  mounted() {},
  methods: {
    open(payload) {
      payload.text = [this.item.name, payload.text].join(".");
      this.$emit("open", payload);
    },
    select(payload) {
      payload = [this.item.set.prefix, this.item.name, payload].join(".");
      this.$emit("select", payload);
    },
    back() {
      this.$emit("back");
    }
  }
};
</script>
