<template>
  <div>
    <div class="font-weight-bold mb-2">
      {{ $t("variables." + set.name) }}
    </div>
    <b-list-group>
      <SetItem
        v-for="(variable, i) in variablesFiltered"
        :key="i"
        :set="set"
        :text="variable[set.text]"
        :value="variable[set.value]"
        @open="open"
        @select="select"
      />
      <div v-if="!variablesFiltered.length" class="text-muted">
        {{
          filter.length
            ? $t("formHelper.noMatchingVariables")
            : $t("formHelper.noVariables")
        }}
      </div>
    </b-list-group>
  </div>
</template>

<script>
import SetItem from "@/components/Tools/FormHelper/Components/VariablesDropdown/SetItem";

export default {
  components: { SetItem },
  props: {
    set: {
      type: Object,
      default: () => {}
    },
    filter: {
      type: String,
      default: ""
    }
  },
  data() {
    return {};
  },
  computed: {
    variablesFiltered: function () {
      // If no filter given, return all items
      if (!this.filter) {
        return this.set.variables;
      }
      // Else filter items
      return this.set.variables.filter(variable => {
        // Extend item name with prepended prefix of set
        let variableName = [this.set.prefix, variable[this.set.text]]
          .join(".");
        // Get variable name length
        let variableNameLength = variableName.split(".").length;
        // Split filter string by dots
        let filter = this.filter.split(".");
        if (filter[0] === this.set.prefix) {
          // If filters first string equals prefix,
          filter = filter.slice(0, variableNameLength);
        } else if (filter.length > 1) {
          filter.shift();
          filter = filter.slice(0, variableNameLength - 1);
        }
        return variableName.includes(filter.join("."));
      });
    }
  },
  mounted() {},
  methods: {
    open(payload) {
      this.$emit("open", payload);
    },
    select(payload) {
      payload = [this.set.prefix, payload].join(".");
      this.$emit("select", payload);
    }
  }
};
</script>
